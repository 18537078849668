.ova_product_categories{

	.item{
		&:hover{
			.image-thumbnail{
				-webkit-transition: all 0.3s ease;
				transition: all 0.3s ease;
				border-radius: 0px;
				&:before{
					-webkit-transition: all 0.3s ease;
					transition: all 0.3s ease;
			    	position: absolute;
			    	content: '';
			    	top: 0;
			    	left: 0;
			    	width: 100%;
			    	height: 100%;
			    	background-color: #111B19;
			    	opacity: 0.5;
			    	z-index: 1
			    }
			    .read-more{
			    	opacity: 1;
			    	top: 50%;
			    	z-index: 2;
			    }
			    img{
			    	-webkit-transition: all 0.3s ease;
					transition: all 0.3s ease;
		    		transform: scale(1.2);
		    	}
			}
		}
		
		.image-thumbnail{
			-webkit-transition: all 0.3s ease;
			transition: all 0.3s ease;
		    position: relative;
		    overflow: hidden;
		    border-radius: 200px 200px 0px 0px;	
		   
		    img{
		    	-webkit-transition: all 0.3s ease;
				transition: all 0.3s ease;
		    	transform: scale(1);
		    	width: 100%;
		    	min-height: 207px;
		    	object-fit: cover;
		    }
		    .read-more{
		    	-webkit-transition: all 0.3s ease;
				transition: all 0.3s ease;
		    	display: inline-flex;
			    padding: 0;
			    width: auto;
			    height: auto;
			    text-align: center;
			    position: absolute;
			    top: 80%;
			    left: 50%;
			    transform: translate(-50%, -50%);
			    justify-content: center;
			    align-items: center;
			    color: #ffffff;
			    opacity: 0;
				
			    font-size: 16px;
			    font-weight: 600px;
			    line-height: 1.4;
			    i{
			    	margin-left: 8px;
			    }

			    &:hover{
			    	-webkit-transition: all 0.3s ease;
					transition: all 0.3s ease;
			    	color: var(--primary-hover);
			    }
		    }
		}
		
		.title{
			margin: 35px 0 0 0;
			text-align: center;
			a{
				transition: all 0.3s ease;
				color: var(--heading);
				font-weight: bold;
				font-size: 24px;
				&:hover{
					transition: all 0.3s ease;
					color: var(--primary-hover);
				}	
			}
		}
		.counts {
			text-align: center;
			text-transform: capitalize;
			margin-top: 5px;
		}
	}

	&.owl-carousel{
		.owl-nav{
			position: absolute;
			bottom: calc(100% + 99px);
			right: 18.5%;
			@media(max-width: 1299px){
				right: 5%;
			}
			button{
				border-radius: 100%;
				width: 36px;
			    height: 36px;
			    display: inline-flex;
			    justify-content: center;
			    align-items: center;
				&.owl-prev{
					border: 1px solid var(--light);
				}
				&.owl-next{
					border: 1px solid var(--light);
					margin-left: 4px;
				}
				i{
					font-size: 22px;
					color: var(--light);
				}

				&:hover{
					border-color: var(--primary-hover);

					i{
						color: var(--primary-hover);
					}
				}
			}
		}

		.owl-dots{
			text-align: center;
			margin-top: 30px;
			.owl-dot{
				outline: none;
				span{
					width: 17px;
					height: 8px;
					margin: 5px;
					background-color: var(--primary);
					opacity: 0.2;
					display: block;
					-webkit-backface-visibility: visible;
					transition: all .2s ease;
					border-radius: 4px;
				}
				&.active{
					span{
						width: 45px;
						opacity: 1;
						background-color: var(--primary);
					}
				}
			}
		}	
	}
	
}