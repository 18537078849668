.ova-product-slider {

	&.elementor-ralated {
		&.owl-carousel {
			.owl-nav {
				button {
					top: -90px;
				}
			}
		}
	}
    
    // template2
	&.ova-product-slider-template2 {
		.ova-product {
			border-radius: 0;
			&:hover {
				.ova-product-review-and-price {
					transition: all .5s ease;
					bottom: 30px;
					opacity: 1;
					visibility: visible;
				}
				.ova-product-day-title-location {
					transition: all .5s ease;
					margin-bottom: 90px;
				}
			}
			.ova_head_product {
				.ova-product-thumbnail {
					border-radius: 0;
					img {
						min-height: 378px;
					}
				}
			}
			.ova_foot_product {
				position: absolute;
			    bottom: 0;
			    right: 0;
			    width: 100%;
			    height: 100%;
			    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 30%, rgba(0, 0, 0, 0.7) 70%);

			    display: flex;
			    flex-direction: column;
			    justify-content: flex-end;
			    overflow: hidden;
			    .ova-product-title {
			    	&:hover {
			    		a {
			    			color: var(--primary);
			    		}
			    	}
			    	a {
			    		color: #fff;
			    	}
			    }
			}
		}
		.ova-tour-day {
			position: static;
			width: fit-content;
			margin-bottom: 10px; 
		}
		.ova-product-day-title-location {
			transition: all .5s ease;
		}

		.ova-product-review-and-price {
			transition: all .5s ease;
			position: absolute;
			bottom: -65px;
			width: calc(100% - 40px);
			visibility: hidden;
			opacity: 0;
		}
		.ova-product-wrapper-price {
			.ova-product-price {
				.new-product-price {
					color: #fff;
				}
				.old-product-price {
					color: rgba(255,255,255,0.8);
				}
			}
		}
		.ova-product-review {}
		.ova-product-location {
			i {
				color: #FFC83E;
			}
			.location {
				color: #fff;
			}
		}
	}

	&.owl-carousel {
		.owl-stage-outer {
			padding-bottom: 20px;
		}
        .owl-nav {
		 	@media (max-width: 576px) {
		 		display: none;
		 	}
			button {
				transition: all .3s ease;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 50%;
	            position: absolute;
	            top: -136px;
	            right: 0;
				width: 36px;
				height: 36px;
				
	            &.owl-prev, &.owl-next  {
	            	border: 1px solid var(--light); 
					&:hover {
						transition: all .3s ease;
						border: 1px solid var(--primary);
		            	i {
		            		transition: all .3s ease;
		            		color: var(--primary);
		            	}
		            }
				}

				&.owl-prev {
					margin-right: 40px;
				}

				i {
					transition: all .3s ease;
					display: inline-flex;
					font-size: 22px;
					color: var(--light);
				}
			}	
		}
	}

	.owl-dots {
		text-align: center;
		.owl-dot{
			outline: none;
			span{
				width: 10px;
				height: 10px;
				margin: 0 3px;
				background: var(--primary);
				opacity: 0.3;
				display: block;
				-webkit-backface-visibility: visible;
				transition: opacity .2s ease;
				border-radius: 10px;
			}
			&.active{
				span{
					opacity: 1;
				}
			}
		}
	}
}