.ovabrw-search {
	margin: 0 auto;
	padding: 25px 28px;
	box-shadow: 10px 2px 40px rgba(0, 0, 0, 0.14);
    border-radius: 10px;
	background: #fff;
	@media (max-width: 1024px) {
		padding-top: 50px;
	}

	&.ovabrw-search-template2 {
		box-shadow: none;
		padding: 0;
		.ovabrw-search-form {
			.ovabrw-s-field {
				row-gap: 15px;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				.search-field {
					flex: 0 0 100%; 
					width: 100%;
					padding: 15px 0;
					border-bottom: 1px solid rgba(17, 27, 25, 0.1);
					&:nth-child(1) {
						flex: 0 0 100%; 
					}
					.ovabrw-label {
						i {
							color: var(--secondary);
						}
					}
				}
				.ovabrw-search-btn {
					margin-top: 15px;
					button.ovabrw-btn {
						min-width: 250px;
						background-color: var(--secondary);  
						&:hover {
							background-color: var(--primary);
						}
					}
				}
			}
		}
	}

	.ovabrw-search-form {

		.ovabrw-s-field {
			display: flex;
			flex-wrap: wrap;
			row-gap: 30px;
		    justify-content: space-between;
		    align-items: center;
		    flex-grow: 1;
			width: 100%;

			.search-field {
				position: relative;
				flex: 0 0 18%;
				@media (max-width: 1024px) {
					flex: 0 0 50%;
				}
				@media (max-width: 460px) {
					flex: 0 0 100%;
				}
				&:nth-child(1) {
					flex: 0 0 28%;
					@media (max-width: 1024px) {
						flex: 0 0 50%;
					}
					@media (max-width: 460px) {
						flex: 0 0 100%;
					}
				}

			    .ovabrw-label {
					display: inline-flex;
				    align-items: center;
				    justify-content: flex-start;
				    margin-bottom: 5px;
					i {
						font-size: 24px;
						color: var(--primary);
						margin-right: 6px;
					}
					svg{
						width: 24px;
						height: auto;
					    fill: var(--primary);
					    margin-right: 8px;
					    path {
					    	fill: var(--primary);
					    }
		            }
					.label {
						font-weight: 700;
						font-size: 16px;
						line-height: 1.3;
						color: var(--text);
					}
				}

				.ovabrw-input {
					width: 100%;

                    // select
					select:not([size]):not([multiple]) {
						background-image: none;
						&:invalid {
							opacity: 0.5;
						}
						optgroup {
							color: var(--heading);
						}
						option {
							color: var(--light);
						}
					}

					input[type="text"], select {
						border: none;
						padding: 0 5px;
						margin: 0 -5px;
						width: 100%;
						font-weight: 400;
						font-size: 16px;
						line-height: 1.3;
						color: var(--light);
						cursor: pointer;
						background-color: transparent;
						&::placeholder {
							color: var(--light);
							opacity: 1;
						}
					}
					.label {
						display: block;
                        font-weight: 600;
						font-size: 16px;
						line-height: 1.5;
						color: var(--heading);			
					}
				}

				.ovabrw-guestspicker-content {
					display: none;
					opacity: 0;
					visibility: hidden;
					position: absolute;
					margin-top: 15px;
					top: 60px;
					left: 0;
					z-index: 1000;
					padding: 16px;
					background-color: #FFFFFF;
					border: 1px solid #CACACA;
					box-sizing: border-box;
					box-shadow: 0px 25px 40px rgba(0, 0, 0, 0.05);
					border-radius: 4px;
					width: 100%;
					min-width: 235px;

					&:before {
						top: -7px;
						left: 50%;
						margin-left: -3.5px;
					    border-right: 7px solid transparent;
					    border-left: 7px solid transparent;
					    border-bottom: 7px solid #efefef;
					    position: absolute;
					    display: inline-block;
					    content: '';
					}

					.guests-buttons {
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding: 10px 0;

						.description {
							label {
                                font-weight: 500;
								font-size: 15px;
								line-height: 1.3;
								color: var(--heading);
								margin-right: 10px;
							}
						}

						.guests-button {
							display: flex;
							align-items: center;

							input {
								width: 30px;
								height: 30px;
								padding: 0;
								text-align: center;
								border: none;
								pointer-events: none;
								font-weight: 500;
								font-size: 15px;
								line-height: 1.3;
								color: var(--heading);
							}

							.guests-icon {
								display: flex;
								align-items: center;
								justify-content: center;
								width: 25px;
    							height: 25px;
							    text-align: center;
							    border: none;
							    border-radius: 4px;
							    background-color: #F9F9F9;
							    color: var(--heading);
							    -webkit-transition: all .3s ease;
								-moz-transition: all .3s ease;
								-ms-transition: all .3s ease;
								transition: all .3s ease;

							    i {
							    	font-size: 10px;
							    }

							    &:hover {
							    	background-color: var(--primary);
							    	i {
                                        color: #FFF;
							    	}
							    }
							}
						}

						&:first-child {
							padding-top: 0;
						}

						&:last-child {
							border-top: 1px solid #EEEEEE;
							padding-bottom: 0;
						}

						&:nth-child(1) {
							border: none;
						}
					}
				}

				.ovabrw-guestspicker {
					width: 100%;
					cursor: pointer;

					.guestspicker {
						font-weight: 400;
						font-size: 16px;
						color: var(--light);

						.gueststotal {
							display: inline-block;
							text-align: left;
							width: 18px;
							margin-left: 4px;
						}
					}
				}

				&.guestspicker-control {
					&.active {
						.ovabrw-guestspicker-content {
							display: block;
							opacity: 1;
							visibility: visible;
						}
					}
				}
			}
			.ovabrw-search-btn {
				@media (max-width: 1024px) {
					flex: 0 0 100%;
					margin: 20px 0;
				}
				button.ovabrw-btn {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;
					width: 100%;
					border-radius: 5px;
					background-color: var(--primary);
					font-weight: 600;
					font-size: 16px;
					line-height: 1.5;
					color: #fff;
					border:unset;
					padding: 18px 32px;
					-webkit-transition: all .3s ease;
					-moz-transition: all .3s ease;
					-ms-transition: all .3s ease;
					transition: all .3s ease;
					i {
						font-size: 24px;
						margin-right: 8px;
					}

					svg{
						width: 24px;
						height: auto;
					    fill: #fff;;
					    margin-right: 8px;
					    path {
					    	fill: #fff;
					    }
		            }

					&:hover {
						-webkit-transition: all .3s ease;
						-moz-transition: all .3s ease;
						-ms-transition: all .3s ease;
						transition: all .3s ease;
						background-color: var(--secondary);
					}
				}
			}

		}

	}
     
    // select 2 in ovabrw search
	.select2-container--default {
		.select2-selection--single {
			border: none;
			.select2-selection__arrow {
				display: none;
			}
			.select2-selection__rendered {
				color: var(--light);
				padding: 0;
			}
		}
	} 

}

// select 2
.select2-dropdown {
	border: none !important;
	margin-top: 10px;
	box-shadow: 10px 2px 40px rgb(0 0 0 / 14%);
}
.select2-search--dropdown {
	padding: 0 0 5px 0 !important; 
	.select2-search__field {
		border: none !important; 
	}
}

.select2-container--default {
	#select2-brw-destinations-select-box-results, .select2-results__options {
		.select2-results__option--selected {
			background-color: #f5f5f5;
			color: var(--primary);
			font-weight: 600;
		}
        .select2-results__option--highlighted{
	    	&.select2-results__option--selectable {
	            background-color: var(--secondary); 
	            color: #fff;
	    	}
	    }
	}
	.select2-selection--single .select2-selection__arrow b {
		background-image: url(../../../assets/img/select-arrow.svg);
		background-repeat: no-repeat;
		background-size: 12px 12px;
		border: none !important;
		height: 12px !important;
		width: 12px !important;
		top: 40% !important;
		opacity : 0.5;
	}
}
