.woocommerce-product-gallery {
	max-width: 600px;
    position: relative;
    margin-bottom: 2rem;
    .woocommerce-product-gallery__trigger {
    	position: absolute;
    	top: 1rem;
    	right: 1rem;
    	z-index: 99;
    }
    a {
    	img {
	    	width: 100%;
	    	height: auto;
	    }
	}
	.flex-viewport {
		margin-bottom: 20px;
	}
	.flex-control-nav.flex-control-thumbs {
		display: flex;
	    list-style: none;
	    justify-content: space-between;
	    margin: 0;
	}
}

.elementor-product-image {
	.ova-gallery-slideshow.owl-carousel {
		&:hover {
			.owl-nav {
				visibility: visible;
				opacity: 1;
				top: 50%;
			}
		}
	    .owl-nav {
			width: 100%;
			height: 0;
			position: absolute;
		    top: 45%;
		    display: flex;
			align-items: center;
			justify-content: space-between;
			visibility: hidden;
			opacity: 0;
			transition: all .7s ease;
			@media screen and (max-width: 1024px) {
				display: none;	
			}

		    button {
		    	display: flex;
		    	align-items: center;
		    	justify-content: center;
		    	width: 62px;
		    	height: 62px;
		    	border-radius: 50%;
		    	background-color: #FFFFFF;
		    	color: var(--secondary);
		    	font-size: 20px;
		    	box-shadow: 0px 4px 40px rgba(43, 89, 255, 0.08);
		    	transition: all .3s ease;

		    	&.owl-prev {
		    		margin-left: 110px;
		    	}

		    	&.owl-next {
		    		margin-right: 110px;
		    	}

		    	&:hover {
		    		background-color: var(--secondary);
		    		color: #FFFFFF;
		    	}
		    }
		}
		.owl-dots {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			visibility: hidden;
	    	opacity: 0;
	    	@media screen and (max-width: 1024px) {
	    		margin-top: 30px;
				visibility: visible;
				opacity: 1;	
			}

			button {
				outline: none;

			    span {
			    	display: flex;
    				width: 7px;
    				height: 7px;
    				background-color: var(--secondary);
    				border-radius: 50%;
    				margin: 0 5px;
    				transition: all .3s ease;

    				&:hover {
    					background-color: var(--secondary-hover);
    				}
			    }

				&.active {
					span {
						width: 16px;
						border-radius: 5px;
						background-color: var(--primary);
					}
				}
			}
		}
	
	}
}
